import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Container } from './section.css';

const Section = ({ children, ...props }) => (
  <Wrapper>
    <Container {...props}>{children}</Container>
  </Wrapper>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Section;
