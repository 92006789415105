import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import theme from 'theme';

const CARD_COLORS = [
  theme.colors.secondaryLight1,
  theme.colors.secondaryLight1,
  theme.colors.secondaryLight1,
];

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 960px;
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: calc(${props => props.theme.spacing.padding} * 2.5);
    ${MEDIA.MAX_MEDIUM`
      margin-bottom: calc(${props => props.theme.spacing.padding} * 1.5);
    `};
  }

  h1,
  h2,
  h3 {
    position: relative;
    display: inline-block;
    text-align: center;
  }

  h4 {
    font-weight: bold;
    text-align: center;
  }

  &.rfs-modifier {

    h3 {
      margin-top: 1rem;
      margin-bottom: 0;
      font-size: 2.8rem;
    }

    h4 {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    .section-container {
      margin-top: 90px;
    }

    .section {
      margin-bottom: 2rem;

      ${MEDIA.MAX_LARGE`
          margin-bottom: 4rem;
        `};
    }

    .header-ribbon {
      h4 {
        font-size: 1.1em;
      }
    }
  }

  &.sports-advisory {
    .one-column {
      width: max-content;
      max-width: 100%;
      margin: auto;
      margin-bottom: 3px;
    }
    .section {
      margin-bottom: 2rem;

      ${MEDIA.MAX_LARGE`
          margin-bottom: 4rem;
        `};
    }
  }

  p {
    font-size: ${props => props.theme.font.size.medium};
    ${MEDIA.MAX_MEDIUM`
      font-size: ${props => props.theme.font.size.small};
    `};
    &:last-child {
      margin-bottom: 0;
    }
  }

  li {
    list-style-type: square;
    margin-left: 20px;
  }

  .section-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: calc(${props => props.theme.spacing.padding} * 1.5);
    margin-top: 50px;
    ${MEDIA.MAX_MEDIUM`
      margin-top: 30px;
    `};
  }

  .section {
    display: flex;
    background: ${CARD_COLORS[0]};
    padding: calc(${props => props.theme.spacing.padding} * 3);
    padding-top: 100px;
    border-radius: 1px;
    color: ${props => props.theme.colors.primary};
    position: relative;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    &:nth-child(2) {
      background-color: ${CARD_COLORS[1]};
    }
    &:nth-child(3) {
      background-color: ${CARD_COLORS[2]};
    }

    &.rfs-red {
      background: #ee3b38;
      

      .column-header {
        position: absolute;
        top: -46px;
        right: 0;
        width: 100%;
        margin: auto;
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-size: 2.5rem;
        background: #002654;
        color: white;
        border-radius: 1px;
      }

      ul {
        color: white;

        &.rfs-text-navy {
          color: #002654;
        }

        li {
          /* color: white; */

          &::after {
            background-color: #900907;
            opacity: 0.4;
          }
        }
      }
      

    }

    &.rfs-blue {
      background: #3954a4;
      li {
        color: white;

        &::after {
          background-color: ${props => props.theme.colors.primary};
          opacity: 0.4;
        }
      }
      
    }

    ul {
      margin: 0 auto;
    }

    li {
      margin: 0 0 40px;
      text-align: center;
      position: relative;
      list-style-type: none;
      &:last-child {
        margin-bottom: 0;
      }
      &::after {
        content: '';
        padding: 0.5px 0;
        background-color: ${props => props.theme.colors.secondaryLight2};
        position: absolute;
        bottom: -20px;
        left: 50%;
        width: 120px;
        margin-left: -60px;
      }
      &:last-child:after {
        padding: 0;
      }
    }
  }

  .header-ribbon {
    position: absolute;
    top: 15px;
    left: -10px;
    border-radius: 1px;
    margin-bottom: calc(${props => props.theme.spacing.padding} * 2);
    padding: ${props => props.theme.spacing.padding};
    padding-left: ${props => props.theme.spacing.padding};
    padding-right: calc(${props => props.theme.spacing.padding} * 2);
    background-color: white;
    &::after {
      content: '';
      position: absolute;
      top: 100%;
      border-bottom: 10px solid transparent;
      border-right: 10px solid #a2a2a2;
      left: 0;
    }
    h4 {
      margin: 0;
      text-align: left;
    }
  }

  .block-container {
    &.container-variant {
      /* margin-top: 2rem; */
      border-radius: 2px;
      box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
    }

    .logo-wrapper {
      width: 160px;
      margin: auto;
    }
    .block-variant {
      margin-bottom: 15px;
      padding: 40px 50px;
      background: white;

      h3 {
        font-size: 3.2rem;
        margin-bottom: 2.5rem;
      }

      .two-column-variant {
        display: flex;
        justify-content: space-around;

        ${MEDIA.MAX_MEDIUM`
          flex-direction: column;
        `};

        .content-inner {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 1rem;
          flex-direction: column;

          a {
            cursor: pointer;
            color: ${props => props.theme.colors.primary};
            transition: all 0.3s ease;

            &:hover {
              color: #ee3b38;
            }
          }
        }
      }
    }
  }

  

  .block {
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
    grid-gap: 40px;
    text-align: start;
    margin-bottom: ${props => props.theme.spacing.padding};
    padding: 40px 50px;

    .content {
      ${MEDIA.MAX_MEDIUM`
        padding: 0 ${props => props.theme.spacing.padding};
      `};
    }

    .title {
      font-size: 28px;
      margin-bottom: 5px;
    }

    .subtitle {
      font-size: ${props => props.theme.font.size.large};
    }

    .gatsby-resp-image-wrapper {
      width: 100%;

      ${MEDIA.MAX_MEDIUM`
        display: block;
        margin-bottom: 25px;
      `};
    }

    ${MEDIA.MAX_MEDIUM`
      display: block;
      padding: 25px 25px;
      text-align: center;
    `};
  }
`;
